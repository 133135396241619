.about-us {
    background-color: #f8f9fa;
    padding: 50px 0;
  }
  



  .about-lm {
    padding: 20px;
  }
  
  .about-lm h2 {
    color: #000fda;
    font-size: 36px;
    font-weight: bolder;
    margin-bottom: 20px;
  }
  
  .about-lm p {
    color: #000000;
    font-size: 20px;
    line-height: 1.6;
    text-align: left;
  }
  

  .about-background-section {
    background-image: url('../images/collegestudy.jpg');
    background-size: cover;
    background-position: center;
    padding: 100px 0; 
    height: 75vh;
    color: #fff;
  }

  .paycourse-page-section {
    background-image: url('../images/bacsetstut.jpg');
    background-size: cover;
    background-position: center;
    padding: 100px 0; 
    height: 75vh;
    color: #fff;
  }
  
  .faq-bg-section {
    background-image: url('../images/faqs.jpg');
    background-size: cover;
    background-position: center;
    padding: 100px 0; 
    height: 75vh;
    color: #fff;
  }

  

  .faq-bg-section {
    background-image: url('../images/faqshowto.jpg');
    background-size: cover;
    background-position: center;
    padding: 100px 0; 
    height: 75vh;
    color: #fff;
  }



  .About-container {
    width: auto;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .about-content {
    background-color: rgba(0, 0, 0, 0.7); 
    padding: 20px;
    border-radius: 10px; 
  }
  
  .about-content h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .about-content p {
    line-height: 1.6;
    text-align: left;
    font-size: 26px;
  }
  

  .our-Mission {
    background-color: #f8f9fa;
    padding: 50px 20px; 
  }
  
  .our-Mission .goalsLm {
    flex: 0 0 100%; 
  }
  
  .our-Mission img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .mission-text {
    padding: 20px; 
    text-align: center; 
  }
  
  .mission-text h2 {
    font-size: 24px;
  }
  
  .mission-text p {
    font-size: 18px;
    text-align: justify;
  }
  
  @media (min-width: 768px) {
    .our-Mission .goalsLm {
      flex: 0 0 50%;
    }
  
    .mission-text {
      text-align: left; 
    }
  }
  
  .Aboutlearn {
    margin-top: 10%;
    background-color: #000fda;
    border-radius: 15px;
    padding: 20px;
    color: white;
  }
  
  .Aboutlearn h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .Aboutlearn select {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #fff;
    color: #000fda;
    font-size: 16px;
    outline: none;
  }
  
  .Aboutlearn select option {
    color: #000fda;
    background-color: white;
  }
  
  .what-to-learn {
    background-image: url('../images/learnlecturemeet.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 25px;
    padding: 100px 0;
  }
  
  .what-to-learn .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .what-to-learn .learntoday {
    color: #fff;
  }
  
  .what-to-learn h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .what-to-learn p {
    font-size: 1.1rem;
    margin-bottom: 30px;
  }
  
  .what-to-learn .btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .what-to-learn .btn:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .what-to-learn .learntoday {
      text-align: center;
    }
  
    .what-to-learn h1 {
      font-size: 2rem;
    }
  
    .what-to-learn p {
      font-size: 1rem;
    }
  }
  

  /*About FAQs*/
  .about-faqs {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: left;
  }

  .about-faqs p{
    text-align: left;
  }


  .accordion {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .accordion-item {
    border-bottom: 1px solid #ccc;
  }
  
  .accordion-title {
    padding: 15px;
    background-color: #f9f9f9;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-weight: bolder;
  }
  
  .accordion-content {
    padding: 15px;
    display: none;
    text-align: justify;
    font-size: 20px;
   
  }
  
  .accordion-item.active .accordion-content {
    display: block;
  }
  

  .pricing-hero-section {
    background-color: #0028ac;
    text-align: center;
    padding: 50px 0;
    color: #fff;
    margin: 0;
    height: 56vh;
    padding-top: 10%;
  }
  
  .pricing-hero-section h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .pricing-hero-section p {
    font-size: 18px;
    color: #ffffff;
  }


  .pricing-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border: 0.5px solid #333;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 5.10%;
}

.pricing-page h1 {
    text-align: center;
}

.pricing-page div {
    margin-bottom: 20px;
}

.pricing-page label {
    display: block;
    margin-bottom: 5px;
}

.pricing-page select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.pricing-page h2 {
    margin-top: 20px;
    font-size: 24px;
    text-align: center;
}

.pricing-plan {
  padding: 50px 0;
}

.payment-price-plan {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.payment-price-plan h3 {
  margin-top: 0;
}

.payment-price-plan p {
  margin-bottom: 10px;
}

.payment-price-plan .btn {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

@media (max-width: 991.98px) {
  .payment-price-plan {
      padding: 10px;
  }
}

@media (max-width: 767.98px) {
  .col-lg-3 {
      flex: 0 0 50%;
      max-width: 50%;
  }
}

@media (max-width: 575.98px) {
  .col-lg-3 {
      flex: 0 0 100%;
      max-width: 100%;
  }
}


/* Custom CSS for Pricing Table */
.pricing-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.pricing-table th,
.pricing-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  color: #000000;
}


.pricing-table th {
  background-color: #f2f2f2;
  
}

@media (max-width: 768px) {
  .pricing-table th,
  .pricing-table td {
      padding: 6px;
      font-size: 12px;
  }
}

.tdp{
  font-weight: bolder;
}


.prog-ram-sect {
  padding: 50px 0;
}

.hero-image {
  text-align: center;
  margin-bottom: 20px;
}

.hero-image img {
  max-width: 100%;
  height: auto;
}

.hkpime {
  border: 1px solid #ccc;
  padding: 20px;
}

.hkpime h3{
text-align: center;
}

.hkpime p{
  text-align: left;
  }



@media (max-width: 768px) {
  .hkpime {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}


.explore-card h1 {
  text-align: center;
  font-size: 36px;
  
}


.explore-card p{
  text-align: center;
  font-size: 23px;
  
}

.prog-190 {
  padding: 50px 0;
}

.prog-190-sector {
  margin-top: 7.5%;
}

.payperprg{
  display: block;
  margin-top: 2.5%;
}

.pmsect-09 {
  padding: 0 15px;
}
.pmsect-09 h2 {
  font-size: 36px;
  color: #000;
}


.pmsect-09 p {
  font-size: 20px;
}

.pmsect-09 p, h2, h3 {
  text-align: left;
  color: #000;
}

.pmsect-09  h2, h3 {
  font-size: 26px;
}


.pmsect-09 p, h2, h3 {
  font-size: 20px;
}


.pmsect-09 img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .prog-190 .row {
    flex-direction: column-reverse;
  }


}


.for-me-organis {
  padding: 50px 0;
  background-color: #f8f9fa;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.container-organisation {
  max-width: 1200px;
  margin: 0 auto;
}

.organisa-lm {
  text-align: center;
}

.organisa-lm h2{
  font-size: 36px;
  color: #000000;
}

.organisa-lm p {
  font-size: 18px;
  color: #000000;
}

.organisa-lm h3 {
  font-size: 24px;
  color: #000000;
}

@media (max-width: 768px) {
  .organisa-lm {
    margin-bottom: 30px;
  }

  
}


.pentagon-container {
  position: relative;
  width: 100%;
  padding-top: 86.602%;
  overflow: hidden;
}

.pentagon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  background-color: #fff; /* Set the background color of the pentagon */
}

.pentagon-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}


.for-orga-primary-container {
  background: url('../images/buildteams.jpg');
  color: white;
  padding: 20px;
  margin-top: 5%;
  margin-bottom: 5%;
  height: 55vh;
}

.primary-org {
  background-color: white;
  color: black;
  padding: 20px;
  margin-top: 9%;
  border-radius: 15px;
}

.primary-org h2,
.primary-org p {
  text-align: center;
}

.primary-org h2 {
  font-size: 36px;
}

.primary-org p {
  font-size: 26px;
}

.org-uniw8l {
  background-color: #f8f9fa; 
  padding: 50px 0;
}

.stats-left,
.stats-right {
  padding: 20px;  
}

.stats-left h2{
  text-align: left;
  font-size: 29px;
}

.stats-left h2, p{
  text-align: left;
}

.stats-left h2, p{
  text-align: left;
}

.stats-left p{
  text-align: left;
  font-size: 20px;
}



.stats-right {
  border-left: 1px solid #ccc; 
}

.colu-stats {
  margin-bottom: 20px;
}

.stat {
  background-color: #fff; 
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  transition: background-color 0.3s ease; 
}

.stat:hover {
  background-color: #f0f0f0; /* Example hover background color */
}
.arrow-circle {
  margin-top: 3.5%;
  width: 150px;
  height: 150px;
  background-image: url('../images/arrowimg.png');
  border-radius: 10%;
  background-size: cover;
  animation: rotateAnimation 4s linear infinite; /* Animation properties */
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stats-list li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #000;
  font-size: 20px;
}

.stats-list li i {
  margin-right: 5px;
}

.prede h2{
  text-align: center;
  
}

.prede p{
  text-align: center;
  font-size: 20px;
}

.grid-courses-pre {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.pre-degree-courses {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 10px;
  margin-bottom: 20px;
}


.testimonial-section {
  padding: 50px 0;
  background-color: #f9f9f9;
}

.testimonial-section p {
  color: #000;
text-align: center;
font-size: 26px;

}


.testimonial-section h2 {
  color: #000;
text-align: left;
font-size: 46px;
font-weight: normal;
font-style: italic;

}


.testimonial-content {
  margin-bottom: 20px;
}

.testimonial-content h2 {
  margin-bottom: 10px;
}

.testimonial-content blockquote {
  font-style: italic;
  margin-bottom: 20px;
}

.testimonial-navigation button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
}

.testimonial-navigation button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .testimonial-section {
    padding: 30px 0;
  }

  .testimonial-content,
  .testimonial-navigation {
    text-align: center;
  }
}


.Corporate-09Sec {
  background-color: #f8f9fa;
  padding: 50px 0;
}

.corp-sect-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.corp-colum {
  flex: 0 0 48%;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .corp-colum {
    flex: 0 0 100%;
  }
}


.columns-container-corporate {
  transition: transform 0.3s ease;
}

.columns-container-corporate:hover {
  transform: translateY(-5px);
}

.columns-container-corporate ul {
  text-align: left;
}

.columns-container-corporate li {
  color: black;
  font-size: 20px;
}


.price-pay-course {
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  transition: background-color 0.3s ease;
  border-radius: 25px;
}

.price-pay-course:hover {
  background-color: blue;
  color: white;
}

.price-pay-course h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center; 
}

.price-pay-course .price-amount {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center; 
}

.price-pay-course hr {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.price-pay-course p {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}


.price-plan-paycourse p{
  text-align: center;
}

.price-plan-paycourse {
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  transition: background-color 0.3s ease;
  border-radius: 25px;
}

.price-plan-paycourse:hover {
  background-color: blue;
  color: white;
}

.price-plan-paycourse {
  background-color: #000;
  color: white;
}

.price-pay-course {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}


.price-plan-paycourse h3 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center; 
}

.price-plan-paycourse .price-amount {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center; 
}

.price-plan-paycourse hr {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.price-plan-paycourse p {
  font-size: 20px;
  margin-bottom: 20px;
}

.price-plan-paycourse label {
  font-size: 20px;
  margin-right: 10px;
}

.price-plan-paycourse input[type='range'] {
  width: 80%;
  margin-right: 10px;
}

.price-plan-paycourse span {
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .price-pay-course {
    padding: 10px;
  }

  .price-pay-course h3 {
    font-size: 20px;
  }

  .price-pay-course .price-amount {
    font-size: 20px;
  }

  .price-pay-course p {
    font-size: 14px;
  }

  .price-plan-paycourse {
    padding: 10px;
  }

  .price-plan-paycourse h3 {
    font-size: 20px;
  }

  .price-plan-paycourse .price-amount {
    font-size: 20px;
  }

  .price-plan-paycourse p {
    font-size: 14px;
  }

  .price-plan-paycourse label {
    font-size: 14px;
  }

  .price-plan-paycourse input[type='range'] {
    width: 70%;
  }

  .price-plan-paycourse span {
    font-size: 14px;
  }
}

.container-plan-price {
  margin-bottom: 5%;
}

.chooseplan h2{
  text-align: left;
  color: #fff;
  font-size: 24px;
}

.chooseplan p{
  text-align: left;
  color: #fff;
  font-size: 20px;
}




.prog-mass-comm {
  background-image: url('../images/campushall.avif');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 100px 0;
  text-align: center;
  height: 75vh;
  width: 100%;
}

.prog-mass-comm .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.prog-mass-comm .header-content {
  max-width: 800px;
}

.prog-mass-comm h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.prog-mass-comm p {
  font-size: 18px;
  margin-bottom: 30px;
}

.prog-mass-comm button {
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.prog-mass-comm button:hover {
  background-color: white;
  color: black;
}

.header-content p{
  font-size: 26px;
  text-align: center;
}


.scroll-to-link {
  display: block;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: #f1f1f1;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
}

.scroll-to-link:hover {
  background-color: #ddd;
}


.scholarships-section {
  background-image: url('../images/Scholarships.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 100px 0;
  text-align: center;
  height: 75vh;
  width: 100%;
}



.scholarships-section {
  background-size: cover;
  background-position: center;
  padding: 50px 0;
}

.scholarships-section .container {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  text-align: center;
}

.scholarships-section h2 {
  font-size: 32px;
  font-weight: bold;
}

.scholarships-section p {
  font-size: 18px;
}


.scholarships-section-requirments {
background-color: #000fda;
  padding: 40px 0;
  color: white;
}

.scholarships-section-requirments .header-content {
  text-align: center;
  margin-bottom: 40px;
}

.requirements-scholar {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.requirements-scholar li {
  padding: 10px 0;
  border-bottom: 1px solid white;
  color: #fff;
}

.requirements-scholar li:hover {
  background-color: rgb(0, 0, 0);
  color: #fff;
}


.header-content h2, p{
  text-align: center;
}

.header-content p{
  font-size: 24px;
}

.header-content h2{
  font-size: 40px;
}


.scholarship {
  padding: 40px 0;
}

.scholarship-column {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.scholarship-column h3 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.scholarship-column ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #000;
}

.scholarship-column ul li {
  padding: 5px 0;
  color: #000;
  text-align: left;
}

.scholarship-column p {
  margin-bottom: 10px;
  text-align: left;
}

.scholarship-column a {
  display: block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.scholarship-column a:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .scholarship-column {
    padding: 10px;
  }

  .scholarship-column h3 {
    font-size: 20px;
  }
}


.Guide-container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
}

.header {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem 0;
}

.guidepage {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem;
}

.guidepage-item {
  display: flex;
  font-size: 35px;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  padding: 1rem;
}

@media (max-width: 768px) {
  .guidepage {
    grid-template-columns: 1fr;
  }
}



.Login-LM-container {
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 1rem;
}

.Login-LM-header {
  background-color: #000000;
  color: white;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
}


.Login-LM-header h1{
  color: white;
  text-align: center;
}

.Login-LM-header p{
  color: white;
  text-align: center;
}


.Login-LM-sections {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  width: 100%;
  padding: 1rem;
}

.Login-LM-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  color: #333;
  text-decoration: none;
  border-radius: 5px;
  padding: 1rem;
  transition: background-color 0.3s;
}

.Login-LM-section:hover {
  background-color: #2fbdff;
  color: #000;
}

.Login-LM-section h2 {
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.Login-LM-section p {
  margin: 0;
  font-size: 1rem;
}

@media (max-width: 1024px) {
  .Login-LM-sections {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .Login-LM-sections {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .Login-LM-section {
    padding: 0.5rem;
  }

  .Login-LM-section h2 {
    font-size: 1.2rem;
  }

  .Login-LM-section p {
    font-size: 0.9rem;
  }
}


.paypercourse-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.paypercourse-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.per-course-left, .per-course-right {
  flex: 1;
  padding: 20px;
}

.per-course-left {
  max-width: 600px;
}

.per-course-left h2 {
  font-size: 2rem;
  color: #134ef0;
  margin-bottom: 20px;
}

.per-course-left p {
  font-size: 1.2rem;
  color: #000000;
  margin-bottom: 20px;
  text-align: left;
}

.per-course-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.per-course-item {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 1.1rem;
  color: #000000;
  border-radius: 15px;
}

.per-course-item:hover {
  background-color: #134afc;
  color: #ffffff;
}


.per-course-right {
  max-width: 600px;
}

.per-course-right img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
}

.per-course-right h3 {
  font-size: 26px;
  color: #033bd4;
  margin-bottom: 20px;
}

.per-course-right p {
  font-size: 24px;
  color: #000000;
  text-align: right;
}

@media (max-width: 768px) {
  .per-course-grid {
    grid-template-columns: 1fr;
  }

  .paypercourse-container {
    flex-direction: column;
  }

  .per-course-left, .per-course-right {
    max-width: 100%;
  }
}



.pay-per-course-prog {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
}

.pay-per-course-column {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pay-per-course-course {
  margin-bottom: 20px;
}

.pay-per-course-column h2 {
  font-size: 1.5rem;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.pay-per-course-course h3 {
  margin-top: 0;
  font-size: 1.25rem;
}

.header-pay-percourse {
  text-align: center;
  color: #000;
}

.header-pay-percourse h1 {
  text-align: center;
  color: #000;
  font-size: 2.65rem;
}

.header-pay-percourse p {
  text-align: center;
  color: #000;
  font-size: 24px;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .pay-per-course-prog {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .pay-per-course-prog {
    grid-template-columns: 1fr;
  }
  
  .header-pay-percourse h1 {
    font-size: 2rem;
  }
  
  .header-pay-percourse p {
    font-size: 18px;
  }
}




.coming-soon-container {
  background-image: url('../images/coming.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 100px 0;
  text-align: center;
  height: auto;
  width: 100%;
}

.coming-soon-content {
  font-size: 26px;
  text-align: center;
  background-color: #000;
  width: 100%;
  border-radius: 15px;
}

.coming-soon-content h2{
  text-align: center;
  font-size: 2.5rem;
}

