.terms-policy-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    line-height: 1.6;
    color: #000000;
    max-width: 800px;
    margin: 0 auto;
    
  }
  
  .terms-policy-container h1 {
    text-align: center;
    margin-bottom: 20px;
    
  }
  
  .terms-section {
    margin-bottom: 20px;
  }
  
  .terms-section h2 {
    color: #007BFF;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .terms-section h3 {
    color: #0056b3;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .terms-section p {
    margin-bottom: 10px;
    text-align: left;
  }
  
  .terms-section a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .terms-section a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .terms-policy-container {
      padding: 10px;
    }
  
    .terms-section h2 {
      font-size: 1.5em;
    }
  
    .terms-section h3 {
      font-size: 1.2em;
    }
  
    .terms-section p {
      font-size: 1em;
    }
  }

  .terms-section ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #000;
    text-align: left;
  }


  .terms-section li{
    text-align: left;
    color: #000000;
}
  

  .privacy-policy-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    line-height: 1.6;
    color: #000000;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .privacy-policy-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-section {
    margin-bottom: 20px;
  }
  
  .privacy-section h2 {
    color: #007BFF;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-section h3 {
    color: #0056b3;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .privacy-section p {
    margin-bottom: 10px;
    text-align: justify;
  }
  
  .privacy-section ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #000;
    text-align: left;
  }


  .privacy-section li{
    text-align: left;
    color: #000000;
}
  @media (max-width: 768px) {
    .privacy-policy-container {
      padding: 10px;
    }
  
    .privacy-section h2 {
      font-size: 1.5em;
    }
  
    .privacy-section h3 {
      font-size: 1.2em;
    }
  
    .privacy-section p {
      font-size: 1em;
      text-align: justify;
    }
  }

  
  .refund-policy-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    line-height: 1.6;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .refund-policy-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .refund-section {
    margin-bottom: 20px;
  }
  
  .refund-section h2 {
    color: #007BFF;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .refund-section h3 {
    color: #0056b3;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .refund-section p {
    margin-bottom: 10px;
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    .refund-policy-container {
      padding: 10px;
    }
  
    .refund-section h2 {
      font-size: 1.5em;
    }
  
    .refund-section h3 {
      font-size: 1.2em;
    }
  
    .refund-section p {
      font-size: 1em;
    }
  }
  