
.admission-details-container {
    display: flex;
    padding: 20px;
    background: linear-gradient(to right, #e8f0fe, #f9f9f9);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
    font-optical-sizing: auto;
}

.admission-details-content {
    display: flex;
    width: 100%;
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
    font-optical-sizing: auto;
}

.admission-info {
    flex: 1;
    margin-right: 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
    font-optical-sizing: auto;
}

.admission-info h2 {
    color: #1900ff;
    margin-bottom: 15px;
    font-size: 24px;
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
    font-optical-sizing: auto;
}

.admission-info h3 {
    margin-top: 20px;
    color: #050505;
    font-size: 20px;
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
    font-optical-sizing: auto;
}

.admission-info ul {
    list-style-type: none;
    padding: 0;
    color: #000;
}

.admission-info li {
    margin-bottom: 10px;
    font-size: 20px;
    color: #000;
}

.admission-form {
    flex: 1;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

#studentAdmissionForm {
    display: flex;
    flex-direction: column;
}

.name-fields {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.form-group {
    flex: 1;
}

label {
    display: block;
    margin-bottom: 8px;
    color: #444;
    font-weight: 600;
    font-size: 14px;
}

input, select, textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus, select:focus, textarea:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    padding-right: 35px;
}

.checkbox-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.checkbox-group label {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}

.checkbox-group input[type="checkbox"] {
    margin-right: 10px;
    width: 18px;
    height: 18px;
}

.submit-button {
    background-color: #007bff;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    font-weight: 600;
}

.submit-button:hover {
    background-color: #0056b3;
}

/* Responsive styles */
@media (max-width: 768px) {
    .admission-details-container {
        padding: 15px;
    }

    .admission-details-content {
        flex-direction: column;
    }

    .admission-info, .admission-form {
        margin-right: 0;
        margin-bottom: 20px;
        width: 100%;
    }

    .admission-info h2 {
        font-size: 22px;
    }

    .admission-info h3 {
        font-size: 18px;
    }

    .admission-info li {
        font-size: 14px;
    }

    .name-fields {
        flex-direction: column;
        gap: 0;
    }

    input, select, textarea {
        font-size: 14px;
        padding: 10px;
    }

    label {
        font-size: 13px;
    }

    .submit-button {
        font-size: 14px;
        padding: 10px;
    }
}

.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}
.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 400px;
    text-align: center;
    border-radius: 5px;
}
.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.bg-yellow {
background: #0F67B1;
color: #fff;
}

.bg-yellow p{
    color: #fff;
    }

    .text-black h2{
        color: #fff;
    }
    

    /* Toast Styling */
    .toast-admission {
        position: fixed;
        top: 90px; 
        width: 100%;
        background-color: #001aff;
        color: white;
        text-align: center;
        padding: 15px;
        font-size: 18px;
        z-index: 999; /* Below the header but still high enough to stay on top of the content */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
       position: static;
    }

.toast-admission p {
    margin: 0;
    font-weight: bold;
}

#countdown {
    font-weight: bold;
}
