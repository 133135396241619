body {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

 h1, h2, p {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
  
}

.job-description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
  background-color: #f5f5f5;
  color: #000;
}

.job-description {
  padding: 2rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  max-width: 800px;
  width: 100%;
  color: #000000;
}

.job-description h1 {
  margin-top: 0;
  font-size: 1.5rem;
  text-align: center;
  color: #000000;
}

.job-description p {
  line-height: 1.5; 
  text-align: left;
  margin-bottom: 1rem;
  color: #000000;
}

.job-description h3 {
  margin-top: 1rem;
  text-align: left;
  margin-bottom: 0.5rem;
  color: #000000;
}

.job-description a {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: #fff; 
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.job-description ul {
  margin: 1rem 0;
  padding: 0 1.5rem;
  color: #000000;
}

.job-description li {
  margin-bottom: 0.5rem;
  line-height: 1.5;
  text-align: left;
  color: #000000;
}

@media (max-width: 768px) {
  .job-description {
      padding: 1rem;
  }
  .job-description h1 {
      font-size: 1.25rem;
  }
}
