.footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 3.5%;
    
  }
  
  .footer-column {
    flex: 1;
    margin: 0 10px;
  }
  
  .footer-column h3 {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .footer-column ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
  }

 .social-footer {
  text-align: left;
  }
  
  .social-footer p{
    text-align: left;
  }

  .footer-column ul li {
    margin-bottom: 5px;
  }
  
  .footer-column a, ul, li {
    color: #fff;
    text-decoration: none;
    
    
  }
  
  .footer-column a:hover {
    text-decoration: underline;
  }
  