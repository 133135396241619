.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  z-index: 1000;
}

.chat-icon {
  font-size: 2.5em;
  cursor: pointer;
  float: right;
}

.chat-icon.active {
  color: #007bff;
}

.chat-window {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.chat-title {
  font-size: 1.2em;
  font-weight: bold;
}

.close-icon {
  font-size: 1.2em;
  cursor: pointer;
}

.chat-message {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin: 10px 0;
}

.question-chat-box {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  margin: 5px 0;
  text-align: center;
}

.question-chat-box:hover {
  background-color: #0056b3;
}
