.learning-articlepage-background-section {
    background-image: url('../imgs/learningthego.jpg');
    background-size: cover;
    background-position: center;
    padding: 100px 0; 
    height: 75vh;
    color: #fff;
    font-family: "DM Serif Text", serif;
    font-weight: 400;
    font-style: normal;
  
}
.articlepage-background-section {
    background-color: #f5f5f5;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.articlepage-container {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.articlepage-content {
    text-align: left;
    font-family: "DM Serif Text", serif;
    font-weight: 400;
    font-style: normal;
}

.articlepage-content h2 {
    font-size: 2.5rem;
    color: #000000;
    margin-bottom: 20px;
    font-family: "DM Serif Text", serif;
    font-weight: 400;
    font-style: normal;
}


.articlepage-content h3 {
    font-size: 1.5rem;
    color: #000000;
    margin-bottom: 20px;
    font-family: "DM Serif Text", serif;
    font-weight: bolder;
    font-style: normal;
}

.articlepage-content p {
    font-size: 1.2rem;
    color: #000000;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: left;
    font-family: "DM Serif Text", serif;
    font-weight: 400;
    font-style: normal;
}

.articlepage-content img {
    width: 100%;
    height: auto;
    margin-top: 20px;
}


.articlepage-content ul, li {
color: #000;
text-align: left;
font-family: "DM Serif Text", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2rem;
}


@media (max-width: 768px) {
    .articlepage-content h2 {
        font-size: 2rem;
    }

    .articlepage-content p {
        font-size: 1rem;
    }
}



.pub-follow-background-section {
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .pub-follow-container {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto 20px;
  }
  
  .pub-follow-content {
    text-align: left;
  }
  
  .pub-follow-content p {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .pub-follow-links {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .pub-follow-links a {
    color: #007bff;
    text-decoration: none;
    margin-right: 10px;
  }
  
  .pub-follow-links a:last-child {
    margin-right: 0;
  }
  
  .pub-follow-links a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .pub-follow-content p {
      font-size: 1rem;
    }
  
    .pub-follow-links {
      flex-direction: column;
      align-items: center;
    }
  
    .pub-follow-links a {
      display: block;
      margin-bottom: 10px;
    }
  
    .pub-follow-links a:last-child {
      margin-bottom: 0;
    }
  }
  