.container-home {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }
  
  
 
  .container-home p{
    font-size: 1.2rem;
  }
  
  .container-home h2, h3, h4{
    font-size: 30px;
  }

  .About-container {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

.admission-requirements {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }


  .admission-requirements p{
    font-size: 1.2rem;
  }
  
  .admission-requirements h2, h3, h4{
    font-size: 30px;
  }


  .container-mx-auto-p-4 {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .container-mx-auto-p-4 p{
    font-size: 1.2rem;
  }
  
  .container-mx-auto-p-4 h2, h3, h4{
    font-size: 30px;
  }

  .about-faqs {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .about-faqs p{
    font-size: 1.2rem;
  }
  
  .about-faqs h2, h3, h4{
    font-size: 30px;
  }

  .UpdatesBlog-container {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .UpdatesBlog-container p{
    font-size: 1.2rem;
  }
  
  .UpdatesBlog-container h2, h3, h4{
    font-size: 30px;
  }

  .container {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .container p{
    font-size: 1.2rem;
  }
  
  .container h2, h3, h4{
    font-size: 30px;
  }

  .featured-section {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
    display: none;
  }

  .featured-section p{
    font-size: 1.2rem;
  }
  
  .featured-section h2, h3, h4{
    font-size: 30px;
  }

  .hero-me {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .hero-me p{
    font-size: 1.2rem;
  }
  
  .hero-me h2, h3, h4{
    font-size: 30px;
  }

  .organization-section {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .organization-section  p{
    font-size: 1.2rem;
  }
  
  .organization-section  h2, h3, h4{
    font-size: 30px;
  }

  .paypercourse-section {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .paypercourse-section  p{
    font-size: 1.2rem;
  }
  
  .paypercourse-section  h2, h3, h4{
    font-size: 30px;
  }

  .video-section {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .video-section  p{
    font-size: 1.2rem;
  }
  
  .video-section h2, h3, h4{
    font-size: 30px;
  }

  .testimonial-section {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .testimonial-section p{
    font-size: 1.2rem;
  }
  
  .testimonial-section h2, h3, h4{
    font-size: 30px;
  }

  .pricing-page {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .pricing-page  p{
    font-size: 1.2rem;
  }
  
  .pricing-page  h2, h3, h4{
    font-size: 30px;
  }

  .prograamessection {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .prograamessection  p{
    font-size: 1.2rem;
  }
  
  .prograamessection  h2, h3, h4{
    font-size: 30px;
  
  }

  .testimonials-section {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .testimonials-section  p{
    font-size: 1.2rem;
  }
  
  .testimonials-section h2, h3, h4{
    font-size: 30px;
  
  }

  .section-learn {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .section-learn  p{
    font-size: 1.2rem;
  }
  
  .section-learn h2, h3, h4{
    font-size: 30px;
  
  }

  .why-lm {
    font-family: "Cormorant Garamond", serif;
    font-weight: normal;
    font-style: normal;
  }

  .why-lm   p{
    font-size: 1.2rem;
  }
  
  .why-lm  h2, h3, h4{
    font-size: 30px;
  
  }

  .scholarships-section h2, h3, h4{
    font-size: 50px;
  
  }