.hero-section {
    text-align: center;
    padding: 50px 20px;
  }
  
  .hero-section h1 {
    font-size: 3.9rem;
    margin-bottom: 20px;
    color: blue;
  }

  .hero-section h4 {
    font-size: 2.9rem;
    margin-bottom: 20px;
    color: rgb(0, 0, 0);
  }
  
  .hero-section span {
    color: black;
  }
  .hero-section p {
    font-size: 2.2rem;
    color: black;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .hero-section h1 {
      font-size: 1.5rem;
    }
  
    .hero-section p {
      font-size: 1rem;
    }
  }
  



/* Products Section */
.products {
  padding: 50px 0;
}

.products-column {
  padding: 20px;
  background-color: #141E46;
  border-radius: 5px;
  transition: background-color 0.3s;
  color: white;
  gap: 3%;
  text-decoration: none;
}

.products-column:hover {
  background-color: #1e2db1;
}

/* Divider Line */
.divider {
  border: 1px solid #000;
  margin: 50px auto;
  width: 80%;
}

@media (max-width: 767.98px) {
  .products .row {
    margin-bottom: 20px;
  }

  .products-column {
    padding: 15px;
    border: 10px solid #ffffff; 
    border-radius: 15px; 
  }
}

.section-product-container.products a {
  text-decoration: none;
}


/* ScrollingSection.css */

.scrolltext {
  display: none;
}
.scrolling-section {
  width: 100%;
  height: 50px; /* Adjust the height as needed */
  overflow: hidden;
  position: relative;
}

.scrolling-text {
  white-space: nowrap;
  position: absolute;
  animation: marquee 20s linear infinite;
  display: flex;
  align-items: center;
}

.scrolling-text span {
  margin-right: 20px;
}

@keyframes marquee {
  from { transform: translateX(100%); }
  to { transform: translateX(-100%); }
}

.scrolling-text a {
  color: #fff;
  text-decoration: none;
  margin-left: 20px;
}

.scrolling-text a:hover {
  text-decoration: underline;
}

.why-lm {
  display: flex;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 5%;
}

.why-lm .left-side {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  color: #000000;
}
.text-align-left {
  color: #000000;
  background-color: #ffffff;
  text-align: left;
}
.why-lm .left-side .image-container {
  display: flex;
  flex-wrap: wrap;
}

.why-lm .left-side .image-container img {
  width: 100%;
  max-width: 200px;
  margin-bottom: 10px;
}
.whyus-columns {
  list-style-type: none;
  padding-left: 0;
  text-align: justify;
  color: #000000;
}

.whyhover{
  background-color: #121481;
  color: #fff;
  padding: 12px 20px;
  border-radius: 15px;
}

.whyhover p, h3 {
  color: #070707;
}

.whyus-columns p, h3{
  color: #ffffff;
}






.why-lm .right-side {
  flex: 1;
  padding: 20px;
  background-color: #121481;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-right-radius: 10%;
  border-bottom-right-radius: 10%;
}
.right-side img {
  border-radius: 15px;
}

.why-lm .right-side h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.why-lm .right-side p {
  font-size: 20px;
  margin-bottom: 20px;
}

.why-lm .right-side button {
  padding: 10px 20px;
  background-color: #fff;
  color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .why-lm {
    flex-direction: column;
  }

  .why-lm .left-side {
    order: 2;
  }

  .why-lm .right-side {
    order: 1;
  }

  .why-lm .left-side .image-container img {
    max-width: 100%;
  }

  .why-lm .left-side .whyus-columns {
    list-style-type: none;
    padding-left: 0;
  }

  .why-lm .left-side .whyus-columns li {
    margin-bottom: 20px;
  }
}
@media (min-width: 769px) {
  .onlineImage img{
    display: none;
  }
}


.where-our-learners-work {
  text-align: center;
  padding: 50px 0;
}

.where-our-learners-work h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  text-align: center;
}

.images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-wrapper {
  width: 150px;
  height: 120px;
  overflow: hidden;
  margin: 12px 10px;
  padding-top: 1.3rem;
  border-radius: 10%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  object-fit: cover;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.image-wrapper:hover img {
  transform: scale(1.1);
}


.organization-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px 0;
}

.organization-left,
.organization-right {
  flex: 1;
  padding: 20px;
}

.organization-left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.org-left-image-container img {
  width: 100%;
  max-width: 800px;
  margin-bottom: 10px;
  border-radius: 15px;
}

.organization-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.data-item {
  text-align: center;
}

.data-item h4 {
  margin-bottom: 5px;
  color: #000000;
  font-weight: bolder;
}

.data-item p {
  margin-bottom: 5px;
  color: #000000;
  font-weight: bolder;
}
.organization-data hr {
  width: 100%;
  border: 0.5px solid #333;
  margin: 5px 0;
}

.organization-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.org-right-image-container img {
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%);
  border-top-left-radius: 10%;
  border-top-right-radius: 89%;
}


.organization-text {
  width: 100%;
}

.organization-text h2 {
  margin-bottom: 10px;
  color: #000ed3;
  font-weight: bolder;
}

.organization-text p {
  margin-bottom: 20px;
  color: #000000;
  font-size: 20px;
}

.organization-text button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .organization-section {
    flex-direction: column;
    text-align: center;
  }

  .organization-left,
  .organization-right {
    padding: 10px;
  }

  .organization-data {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .organization-data hr {
    margin: 5px auto;
  }
}





.video-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.for-indKO h2{ 
  text-align: left;
  font-size: 36px;
  font-weight: bolder;
  color: #000ed3;
}

.for-indKO p{ 
  text-align: left;
  font-size: 26px;
}


.video-section .for-indKO {
  flex: 1;
  padding: 20px;
}

.video-section .for-indKO-right {
  flex: 1;
  padding: 20px;
}

@media (max-width: 768px) {
  .video-section {
    flex-direction: column;
  }

  .video-section .for-indKO,
  .video-section .for-indKO-right {
    width: 100%;
  }
}

.testimonials-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.testimonial {
  width: 45%;
  padding: 20px;
  margin: 10px;
  background-color: #f8f9fa;
  border-radius: 10px;
  text-align: center;
}

.quote {
  font-style: italic;
  margin-bottom: 10px;
}

.author {
  font-weight: bold;
}

.globalheader h2{
  text-align: center;
  color: #1e2db1;
  font-size: 45px;
}

@media (max-width: 768px) {
  .testimonial-card {
    width: 100%;
    max-width: 800px;
  }
  
}

.hero-me {
  background-color: #0d41e1;
  color: #fff;
  border-radius: 24px 30%;
  padding: 50px 0;
  margin-left: 2%;
  margin-right: 2%;
  text-align: center;
  height: 89vh;
  padding-top: 5%;
}

.hero-me h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero-me p {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.hero-data {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
 
}

.hero-stat {
  flex: 1;
  margin: 0 10px;
  background-color: #000;
  border-radius: 12px;
  padding: 2.50%;
  color: #fff;
}

.hero-stat:hover {
  background-color: #ffffff;
  color: #000000;
}
.hero-stat h3 {
  font-size: 2rem;
}

.hero-stat p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .hero-me {
    height: auto;
    padding-top: 5%;
    border-radius: 5px;
  }

  .hero-data {
    flex-direction: column; 
    align-items: center; 
  }

  .hero-stat {
    margin: 10px 0; 
    padding: 15px;
    min-width: 80%; 
  }

  .hero-stat h3 {
    font-size: 1.5rem;
  }

  .hero-stat p {
    font-size: 0.9rem;
  }

.testimonials-section {
  display: none;
}

}

.featured-section {
  padding: 50px 0;
  background-color: #f8f9fa;
  margin-top: 5.9%;
  margin-bottom: 5.9%;
}

.featured-news {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.featured-news-item {
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.featured-news-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.featured-news-item img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.featured-news-content {
  text-align: center;
}

.featured-news-content h3 {
  margin-top: 0;
}

.featured-news-content p {
  margin-bottom: 10px;
}

.btn {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.btn:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .featured-news-item {
    width: 100%;
  }
}


body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.container-FlipCard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.card {
  perspective: 1000px;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 300px;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-front {
  background-color: #fff;
}

.card-back {
  background-color: #f0f0f0;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card-back h2 {
  margin-top: 0;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.btn:hover {
  background-color: #0056b3;
}



.prograamessection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
}






.progsect-left {
  flex: 1;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.progsect-right {
  flex: 1;
  padding-left: 20px;
}

.progsect-right img {
  width: 100%;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .prograamessection {
    flex-direction: column;
    padding: 20px 0;
  }

 
  .progsect-left,
  .progsect-right {
    padding: 0;
    margin-bottom: 20px;
  }

  .progsect-right img {
    border-radius: 0;
  }
}

.progra-column {
  padding: 0 15px;
}

.progra-card {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.progra-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  background-color: #1C1678;
  color: #fff;
}

@media (max-width: 767.98px) {
  .progsect-left,
  .progsect-right {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .progra-column {
    padding: 0;
  }
}

.left-side p{
  text-align: left;
}


.review-section {
  display: none; 
}

@media only screen and (max-width: 768px) {
  .review-section {
      display: block; 
      padding: 20px;
      border-top: 1px solid #ccc;
  }

  .review {
      margin-bottom: 20px;
  }

  .reviewer-info {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
  }

  .reviewer-info {
  color: #000;
  }

  .reviewer-info img {
      border-radius: 50%;
      margin-right: 10px;
  }

  .review-text {
      font-style: italic;
  }
}

/* LearnSection */
.section-learn {
  padding: 50px 0;
  text-align: center;
  border-radius: 25px;
  background-color: #121481;
  margin: 2.0%;
  color: #ccc;
  margin-bottom: 3.0%;
}
.learn-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.learn-left {
  flex: 1;
  padding-right: 20px;
}

.learn-left h2{
  color: #fff;
  font-size: 26px;
  font-weight: bolder;
 }


.learn-left p{
 color: #fff;
 font-size: 26px;
}



.learn-left img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  display: none;
}

.learn-right {
  flex: 1;
  padding-left: 20px;
}

.learn-right h3 {
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 36px;
}

.learn-right p {
  color: #ffffff;
}
.learn-right select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.get-started {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.get-started:hover {
  background-color: #0056b3;
}


@media only screen and (max-width: 768px) {
  .section-learn {
      padding: 30px;
  }

  .learn-right {
      max-width: none;
      padding: 0;
  }
  
  .learn-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
}


/* NotFound */
.not-found-container {
  text-align: center;
  margin-bottom: 10%;
}

.not-found-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.not-found-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
}

.not-found-button:hover {
  background-color: #0056b3;
}


.programe-course {
  text-align: center;
  font-size: 20px;
  color: #000;
}


.HeroBlog-section {
  background-color: #10439F;
  color: white;
  text-align: center;
  padding: 50px 20px;
  border-radius: 10px;
  margin: 20px 0;
}

.HeroBlog-section h1 {
  margin-bottom: 20px;
}

.HeroBlog-section p {
  margin-bottom: 20px;
}

.HeroBlog-section .hero-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.HeroBlog-section .hero-button:hover {
  background-color: #000000;
  color: #fff;
}

.pay-per-course-course span{
  font-weight: bolder;
}