body {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

 h1, h2, p {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
  
}

.Jobs {
    font-family: Arial, sans-serif;
  }
  
  .hero-section-career{
    background: #007bff;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .Job-Column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .job-item {
    border: 1px solid #ccc;
    padding: 15px;
    margin: 10px;
    width: 80%;
    text-align: left;
    background: #f9f9f9;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .job-item:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .job-item h2 {
    margin: 0;
    padding: 0;
  }
  
  .job-item p {
    margin: 10px 0;
  }
  
  @media (max-width: 768px) {
    .Job-Column {
      width: 100%;
    }
  
    .job-item {
      width: 90%;
    }
  }

  


  .apply-page {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    font-family: Arial, sans-serif;
    display: none;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 10px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="file"],
  textarea {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  textarea {
    height: 100px;
  }
  
  button {
    background: #007bff;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.3s ease-in-out;
  }
  
  button:hover {
    background: #0056b3;
  }
  
  