.UpdatesBlog-container {
    display: grid;
    grid-template-rows: auto 1fr;
    height: auto;
  }
  
  .UpdatesBlog-header {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 1rem 0;
  }
  
  .UpdatesBlog {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    padding: 1rem;
  }
  
  .UpdatesBlog-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    color: #333;
    text-decoration: none;
    border-radius: 5px;
    padding: 1rem;
  }
  
  .UpdatesBlog-item:hover {
    background-color: #e0e0e0;
  }
  
  .UpdatesBlog-image {
    max-width: 100%;
    border-radius: 5px;
    margin-bottom: 0.5rem;
  }
  
  .UpdatesBlog-title {
    margin: 0;
    font-size: 1.2rem;
  }
  
  @media (max-width: 768px) {
    .UpdatesBlog {
      grid-template-columns: 1fr;
      height: auto;
    }
  }
  