.admission-requirements {
    font-family: 'Roboto', sans-serif;
    padding: 20px;
    margin: auto;
    max-width: 1200px;
    line-height: 1.6;
    color: #333;
  }
 
  .header {
    text-align: center;
    padding: 20px 0;
    background-color: #f8f9fa;
    border-bottom: 2px solid #e9ecef;
  }
  
  .header h1 {
    font-size: 2.5rem;
    color: #000000;
    margin: 0;
  }
  
  .section {
    padding: 20px;
    background-color: #ffffff;
    color: #000000;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .section h2 {
    font-size: 2rem;
    color: #2980b9;
    margin-bottom: 15px;
  }
  
  .section h3 {
    font-size: 1.5rem;
    color: #34495e;
    margin-top: 15px;
  }
  
  .section p {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .section ul {
    list-style: disc;
    margin-left: 20px;
    text-align: left;
  }
  

  .section ul li {
    margin-bottom: 10px;
    text-align: left;
    color: #000;
  }
  
  @media (max-width: 768px) {
    .header h1 {
      font-size: 2rem;
    }
  
    .section h2 {
      font-size: 1.5rem;
    }
  
    .section h3 {
      font-size: 1.2rem;
    }
  
    .section {
      padding: 15px;
    }
  }
  