.NotificationAlert h1, h2, p {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.text-left {
  color: #000;
  font-weight: bolder;
  text-align: left;
}

.NotificationAlert {
  font-family: Raleway;
}

.NotificationAlert-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.NotificationAlert-popup-content {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
}

/* Close button styling */
.NotificationAlert-close-btn {
  color: #000000;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.NotificationAlert-close-btn:hover,
.NotificationAlert-close-btn:focus {
  color: #000;
  text-decoration: none;
}

/* Accept button styling */
.NotificationAlert-accept-btn {
  background-color: #000000; 
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.NotificationAlert-accept-btn:hover {
  background-color: #000000; 
}

/* Mobile responsiveness */
@media screen and (max-width: 600px) {
  .NotificationAlert-popup-content {
    width: 90%; 
    padding: 15px;
    max-height: 90%; 
    overflow-y: auto; 
  }
}

@media screen and (max-width: 400px) {
  .NotificationAlert-popup-content {
    width: 95%; 
    padding: 10px;
    max-height: 90%; 
    overflow-y: auto; 
  }

  .NotificationAlert-close-btn {
    font-size: 24px; 
  }
}

/* Larger screen responsiveness */
@media screen and (min-width: 1024px) {
  .NotificationAlert-popup-content {
    width: 70%; 
    max-width: 900px; 
  }
}

@media screen and (min-width: 1440px) {
  .NotificationAlert-popup-content {
    width: 60%;
    max-width: 1200px; 
  }
}
