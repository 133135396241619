.pre-degree-program-course-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px 20px;
    background-color: #f5f5f5;
    display: none;
}

.pre-degree-program-course-header {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.pre-degree-program-course-paragraph {
    font-size: 1.2rem;
    color: #000000;
    margin-bottom: 30px;
    max-width: 600px;
}

.pre-degree-program-course-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1rem;
}

.pre-degree-program-course-button:hover {
    background-color: #0056b3;
}


.prog-mass-comm, .scroll-to-section, .prog-190{
    font-weight: normal;
  }



  /* General styles for the sections */
.scroll-to-section {
    background-color: #f9f9f9;
    padding: 20px;
    border-bottom: 1px solid #ddd;
    text-decoration: none;
}

.scroll-to-link {
    text-decoration: none;
}

.scroll-to-link {
    display: block;
    color: #007bff;
    text-decoration: none;
    padding: 10px 0;
    font-size: 16px;
}

.scroll-to-link:hover {
    text-decoration: underline;
}

/* Section styles */
.prog-mass-comm {
    background-color: #fff;
    padding: 40px 20px;
}

.prog-mass-comm .header-content {
    text-align: center;
}


.prog-mass-comm h1 {
    color: #fffefe;
    font-size: 2em;
    margin-bottom: 20px;
}

.prog-mass-comm p {
    color: #ffffff;
    font-size: 1.1em;
}

/* Styles for specific sections */
.admission-requirements, .fees, .course-outline, .learning-model, .scholarship, .internship, .remote-job, .benefits {
    background-color: #f4f4f4;
    padding: 40px 20px;
    border-bottom: 1px solid #ddd;
}

.admission-requirements h2, .fees h2, .course-outline h2, .learning-model h2, .scholarship h2, .internship h2, .remote-job h2, .benefits h2 {
    color: #007bff;
    font-size: 1.8em;
    margin-bottom: 20px;
}

.admission-requirements p, .fees p, .course-outline p, .learning-model p, .scholarship p, .internship p, .remote-job p, .benefits p {
    color: #666;
    font-size: 1.1em;
    margin-bottom: 20px;
}

.admission-requirements ul, .fees ul, .course-outline ul, .learning-model ul, .scholarship ul, .internship ul, .remote-job ul, .benefits ul {
    list-style-type: disc;
    padding-left: 20px;
}

.admission-requirements li, .fees li, .course-outline li, .learning-model li, .scholarship li, .internship li, .remote-job li, .benefits li {
    margin-bottom: 10px;
}

.admission-requirements ul, p, li, h2{
    text-align: left;
}

.admission-requirements p{
    color: #000;
}

.container-text p {
    color: #000;
}



.img-fluid {
    max-width: 100%;
    height: auto;
}

/* Specific styles for images */
.prog-190 img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

header-content {
    margin-top: 20px;
}


/* Learning Model Section Styles */
.learning-model {
    padding: 2rem;
    background-color: #f8f9fa;
}

.learning-model h2 {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    color: #333;
}

.pre-accordion-button {
    font-size: 1.2rem;
    font-weight: bold;
    color: #007bff;
    background-color: #e9ecef;
    border: 1px solid #dee2e6;
    text-align: left;
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
}

.pre-accordion-button:not(.collapsed) {
    color: #0056b3;
    background-color: #f1f3f5;
}

.pre-accordion-button .icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
}

.pre-accordion-button:not(.collapsed) .icon {
    transform: rotate(180deg);
}

.pre-accordion-body {
    padding: 1.5rem;
    background-color: #ffffff;
}

.predegree-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
}

.predegree-table th,
.predegree-table td {
    padding: 0.75rem;
    border: 1px solid #dee2e6;
    text-align: left;
}

.predegree-table th {
    background-color: #f8f9fa;
}

.text-end {
    text-align: right;
}

.font-weight-bold {
    font-weight: bold;
}

@media (max-width: 768px) {
    .pre-accordion-button {
        font-size: 1rem;
    }
    .predegree-table th,
    .predegree-table td {
        font-size: 0.875rem;
    }
}


.pre-accordion-button {
    position: relative;
    padding-right: 2rem; /* Space for the icon */
}

.material-icons {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
}
